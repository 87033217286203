<template>
  <div id="authentication">
    <vue-headful :title="title + ($appName ? ' | ' + $appName : '')" />
    <b-container>
      <b-row>
        <b-col id="action-area" md="6" offset-md="4">
          <h1 class="text-center">Sign in</h1>
          <p class="text-center">
            New to {{ $appName ? $appName : Reducer }}?
            <router-link :to="{ name: 'signup' }">Create an account</router-link>
          </p>
          <p class="text-center">{{ message }}</p>
          <div v-bind:class="{ cognitoError: cognitoResponse }" class="text-center">
            {{ cognitoResponse }}
          </div>
          <b-form v-on:submit.prevent="signin">
            <b-form-group id="emailGroup">
              <b-form-input
                v-bind:disabled="busy"
                size="lg"
                v-model="email"
                required
                class="Control__input"
                name="email"
                type="email"
                ref="email"
                autocomplete="username"
                placeholder="Email Address"
              />
            </b-form-group>
            <b-form-group id="passwordGroup">
              <b-form-input
                v-bind:disabled="busy"
                size="lg"
                v-model="password"
                required
                class="Control__input"
                name="password"
                type="password"
                ref="password"
                autocomplete="current-password"
                placeholder="Password"
              />
            </b-form-group>
            <b-row>
              <b-button
                v-bind:disabled="busy"
                class="authButton"
                size="lg"
                block
                type="submit"
                variant="primary"
              >
                <div v-if="busy">
                  <Busy light :margin="0" />
                </div>
                <div v-else>Sign in</div>
              </b-button>
            </b-row>
          </b-form>
          <b-row class="justify-content-center my-2">
            <AuthButton
              v-if="showXero"
              :buttonImageUrl="require('@/assets/images/xero-pad.png')"
              buttonText="Sign in with Xero"
              cognitoProvider="Xero"
              v-on:updateBusy="busy = $event"
              :busy="busy"
            />
            <AuthButton
              v-if="showQuickBooks"
              :buttonImageUrl="require('@/assets/images/qb-pad.png')"
              buttonText="Sign in with QuickBooks"
              cognitoProvider="Quickbooks"
              v-on:updateBusy="busy = $event"
              :busy="busy"
            />
            <AuthButton
              v-if="showGoogle"
              :buttonImageUrl="require('@/assets/images/google_light.svg')"
              buttonText="Sign in with Google"
              cognitoProvider="Google"
              v-on:updateBusy="busy = $event"
              :busy="busy"
            />
          </b-row>
          <p class="text-center">
            <router-link :to="{ name: 'request-password-reset' }">Forgot Password?</router-link>
          </p>
          <p class="text-center">
            <router-link :to="{ name: 'resend-verification' }"
              >Resend Verification Email</router-link
            >
          </p>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<style lang="scss">
@import "@/styles/authentication.scss";
</style>

<script>
import CognitoAuth from "@/cognito/cognitoauth";
import AuthButton from "@/components/AuthButton";
import Busy from "@/components/Busy";
import { sso } from "@/config/reducerproperties";
import { EventBus } from "../../components/eventbus";

export default {
  name: "Signin",
  mounted: function() {
    document.body.className = "authenticationBody";
    if (this.emailprop) {
      this.email = this.emailprop;
      this.$refs.password.focus();
    }
  },
  created() {
    this.showXero = sso.xero;
    this.showQuickBooks = sso.quickbooks;
    this.showGoogle = sso.google;
    EventBus.$on("signin-page-stop-loading", () => (this.busy = false));
  },
  destroyed: function() {
    document.body.className = "";
    EventBus.$off("signin-page-stop-loading");
  },
  components: {
    AuthButton: AuthButton,
    Busy
  },
  data() {
    return {
      title: "Sign In",
      email: "",
      password: "",
      cognitoResponse: null,
      busy: false,
      showXero: null,
      showQuickBooks: null,
      showGoogle: null
    };
  },
  props: ["message", "emailprop"],
  methods: {
    cognitoFail(result) {
      this.busy = false;
      switch (result.code) {
        case "UserNotFoundException":
          this.cognitoResponse =
            "Sorry, we could not find a user with that email address, please sign up";
          break;
        case "UserNotConfirmedException":
          this.cognitoResponse =
            "Sorry, we can't sign you in yet as your email address not been verified. Please check your emails for the verification link or request a new one";
          break;
        default:
          this.cognitoResponse = result.message;
      }
    },
    signin() {
      this.busy = true;
      this.cognitoResponse = null;
      CognitoAuth.signin(this.email.toLowerCase(), this.password, this.cognitoFail);
    }
  }
};
</script>
